import { SwitchCaseProps } from './SwitchCase.types';

export function SwitchCase<T = string | number | boolean, K extends string = string>({
  value,
  caseBy,
  defaultElement,
}: SwitchCaseProps<T, K>) {
  const stringValue = value?.toString();

  for (const [key, element] of Object.entries(caseBy)) {
    if (key === stringValue) return (<>{ element }</>);
  }

  return (<>{ defaultElement }</>);
}
