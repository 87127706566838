import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { CardSkeleton } from '../Skeleton/Skeleton';
import { TemplateItemContainer } from './TemplateItemContainer';

export type TemplateItemSkeletonProps = HTMLAttributes<HTMLDivElement>

export const TemplateItemSkeleton = (props: TemplateItemSkeletonProps) => {
  return (
    <TemplateItemContainer
      className={classNames(
        'TemplateItemSkeleton',
        props.className
      )}
      style={props.style}
    >
      <CardSkeleton />
    </TemplateItemContainer>
  );
};
