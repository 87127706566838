/**
 * API와 같은 다양한 비동기 호출에서 고의적으로 딜레이를 시켜서 로딩 화면을 보여주기 위한 클래스입니다.
 */
export class DummyDelay {
  /**
   * promise api를 전달받으면 promise api가 완료 되기 까지의 실제 시간과 최소 시간을 비교하여
   * 설정한 최소 시간보다 api의 응답 시간이 길면 가짜 딜레이를 사용하지 않고
   * 만약 그보다 짧으면 남은 시간만큼 가짜 딜레이를 진행합니다.
   * @params promise
   * @params delay
   * @params promise
   */
  public static start<T>(promise: Promise<T>, delay: number) {
    return new Promise<T>((resolve, reject) => {
      const startTime = (new Date()).getTime();

      promise
        .then((response) => {
          const endTime = (new Date()).getTime();
          const takenTime = endTime - startTime;

          if (takenTime > delay) {
            resolve(response);
          } else {
            window.setTimeout(() => resolve(response), delay - takenTime);
          }
        })
        .catch((error) => {
          const endTime = (new Date()).getTime();
          const takenTime = endTime - startTime;

          if (takenTime >= delay) {
            reject(error);
          } else {
            window.setTimeout(() => reject(error), delay - takenTime);
          }
        });
    });
  }
}
