
import { css, keyframes } from '@mui/material';
import classNames from 'classnames';
import { memo } from 'react';

import { ComponentProps } from '~/src/types/common';

import { SwitchCase } from '../SwitchCase';

export type SKELETON_TYPES = 'avatar' | 'text';

const DEFAULT_SKELETON_COLOR = '#DDDDDD';

export interface SkeletonProps extends ComponentProps {
  type: SKELETON_TYPES;
  lines?: number;
  lineHeight?: number;
  loading?: boolean;
}

export const Skeleton = (props: SkeletonProps) => {
  const loading = props.loading ?? true;

  return (
    <SwitchCase
      value={props.type}
      caseBy={{
        'avatar': (<SkeletonAvatar
          className={props.className}
          style={props.style}
          loading={loading}
        />),
        'text': (<SkeletonText
          className={props.className}
          style={props.style}
          lines={props.lines}
          lineHeight={props.lineHeight}
          loading={loading}
        />),
      }}
      defaultElement={null}
    />
  );
};

const LoadingIndicator = memo(() => {
  const pulse = keyframes`
    from {
      background-color: transparent;
    }
    50% {
      background-color: #ffffff33;
    }
    to {
      background-color: transparent;
    }
  `;

  return (
    <div
      css={css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        animation: 1s ease-in-out 0.5s infinite normal none running ${pulse};
      `}
    />
  );
});

interface SkeletonAvatarProps extends ComponentProps {
  loading?: boolean;
}

const SkeletonAvatar = memo((props: SkeletonAvatarProps) => {
  return (
    <div
      className={classNames(
        props.className
      )}
      style={props.style}
      css={css`
        position: relative;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        background-color: ${DEFAULT_SKELETON_COLOR};
        overflow: hidden;
      `}
    >
      { props.loading && <LoadingIndicator /> }
    </div>
  );
});

interface SkeletonTextProps extends ComponentProps {
  lines?: number;
  lineHeight?: number;
  loading?: boolean;
}

const SkeletonText = memo((props: SkeletonTextProps) => {
  const lines = props.lines ?? 1;
  const lineHeight = props.lineHeight ?? 16;

  return (
    <>
      {
        Array(lines).fill(undefined).map((_, key) => {
          return (
            <div
              key={key}
              className={classNames(
                props.className
              )}
              style={{
                ...props.style,
                minHeight: lineHeight,
              }}
              css={css`
                position: relative;
                width: 100%;
                background-color: ${DEFAULT_SKELETON_COLOR};
                overflow: hidden;
              `}
            >
              { props.loading && <LoadingIndicator /> }
            </div>
          );
        })
      }
    </>
  );
});
