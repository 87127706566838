import { useEffect, useState } from 'react';

const MODE_DARK = 'dark';
const MODE_LIGHT = 'light';
const MEDIA_DARK = '(prefers-color-scheme: dark)';

export const useDarkMode = () => {
  const [mode, setMode] = useState<typeof MODE_DARK | typeof MODE_LIGHT>();
  const [isInitialized, setIsInitialized] = useState(false);
  const isDark = mode === MODE_DARK;
  const isLight = mode === MODE_LIGHT;

  useEffect(() => {
    const isDark = window.matchMedia(MEDIA_DARK).matches;
    setMode(isDark ? MODE_DARK : MODE_LIGHT);
    setIsInitialized(true);
  }, []);

  /**
   * 서비스에 접속한 상태로 브라우저 테마를 변경하는 것을 감지합니다.
   */
  useEffect(() => {
    window.matchMedia(MEDIA_DARK).addEventListener('change', ({ matches }) => {
      setMode(matches ? MODE_DARK : MODE_LIGHT);
    });
  }, []);

  return {
    mode,
    isInitialized,
    isDark,
    isLight,
  };
};
