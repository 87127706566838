import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { MouseEventHandler, ReactNode } from 'react';

import { AspectRatio } from '~/src/components/app/AspectRatio';
import { ComponentProps } from '~/src/types/common';

export interface TemplateItemContainerProps extends ComponentProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  children?: ReactNode;
}

export const TemplateItemContainer = (props: TemplateItemContainerProps) => {
  const theme = useTheme();

  return (
    <motion.a
      className={props.className}
      initial="rest"
      animate="rest"
      whileFocus="focus"
      whileHover="hover"
      style={props.style}
      href="#"
      onClick={(event) => {
        event.preventDefault();

        props.onClick && props.onClick(event);
      }}
    >
      <motion.div
        variants={{
          focus: {
            scale: 1.05,
          },
          hover: {
            scale: 1.05,
          },
        }}
      >
        <AspectRatio
          ratio="1:1"
        >
          <div
            className={classNames('TemplateItem__Thumbnail')}
            css={css`
              position: relative;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              overflow: hidden;
              background-color: ${theme.app.colors['neutral-color-09']};
              border: 1px solid ${theme.app.colors['neutral-color-07']};
            `}
          >
            { props.children }
          </div>
        </AspectRatio>
      </motion.div>
    </motion.a>
  );
};
