import { css } from '@emotion/react';
import classNames from 'classnames';

import { ComponentProps } from '~/src/types/common';

import { AspectRatio } from '../../app/AspectRatio';
import { Skeleton } from '../../app/Skeleton';

type CardSkeletonProps = ComponentProps

export const CardSkeleton = (props: CardSkeletonProps) => {
  return (
    <div
      className={classNames(
        props.className,
        'CardSkeleton'
      )}
      style={props.style}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      `}
    >
      <div
        className="CardSkeleton__Container"
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            width: 30%;
            margin: auto;
          `}
        >
          <AspectRatio
            ratio="1:1"
          >
            <Skeleton
              type="avatar"
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </AspectRatio>
        </div>

        <div
          css={css`
            width: 60%;
            margin: auto;
            margin-top: 20px;
          `}
        >
          <AspectRatio
            ratio="5:1"
          >
            <Skeleton
              type="text"
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </AspectRatio>
        </div>
      </div>
    </div>
  );
};
