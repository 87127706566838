import { css } from '@emotion/react';
import { Typography, useTheme  } from '@mui/material';
import { MouseEventHandler } from 'react';

import { Button, ButtonProps } from '~/src/components/app/Button/v1';

export interface CustomizeButtonProps extends ButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const CustomizeButton = (props: CustomizeButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      css={css`
        background-color: ${theme.app.colors['pure-black']} !important;
        height: 56px !important;
      `}
      tabIndex={-1}
    >
      <Typography
        variant="body2"
        css={css`
          color: ${theme.app.colors['neutral-color-10']};
        `}
      >
        Customize
      </Typography>
    </Button>
  );
};
