import { css } from '@emotion/react';
import { HTMLAttributes } from 'react';
import Loading, { LoadingProps } from 'react-loading';

interface PanelItemLoadingProps {
  props?: HTMLAttributes<HTMLDivElement>;
  loadingProps?: LoadingProps;
}

export const PanelItemLoading = (props: PanelItemLoadingProps) => {
  const { props: wrapperProps, loadingProps } = props;

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background-color: #0000004e;
      `}
      {...wrapperProps}
    >
      <Loading
        type="spin"
        width={'30px'}
        height={'30px'}
        {...loadingProps}
      />
    </div>
  );
};
