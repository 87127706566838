import { css } from '@emotion/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { HTMLAttributes, MouseEvent, useState } from 'react';

import { DummyDelay } from '~/src/helpers/DummyDelay';

import { PanelItemLoading } from '../../screens/Editor/components/Panels/components/PanelItemLoading';
import { CustomizeButton } from './components/CustomizeButton';
import { TemplateItemContainer } from './TemplateItemContainer';

export interface TemplateItemProps extends HTMLAttributes<HTMLDivElement> {
  thumbnail?: string;
  showCustomizeButton?: boolean;
  showLoading?: boolean;
  onCustomize?: (event: MouseEvent<HTMLAnchorElement>) => Promise<void>;
}

export const TemplateItem = ({
  thumbnail,
  showCustomizeButton,
  showLoading,
  onCustomize,
  ...others
}: TemplateItemProps) => {
  const isShowCustomizeButton: boolean = showCustomizeButton ?? true;
  const isShowLoading: boolean = showLoading ?? false;

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <TemplateItemContainer
      { ...others }
      className={classNames(
        others.className,
        'TemplateItem'
      )}
      css={css`
        overflow: hidden;
        pointer-events: ${loading ? 'none' : 'all'};
      `}
      onClick={async (event) => {
        if (isShowLoading) {
          if (loading) {
            return;
          }

          setLoading(true);
          onCustomize && DummyDelay.start(onCustomize(event), 500).finally(() => setLoading(false));

          return;
        }

        onCustomize && await onCustomize(event);
      }}
    >
      {
        thumbnail &&
        <Image
          className="TemplateItem__Image"
          src={thumbnail}
          width={500}
          height={500}
          alt=""
          css={css`
            width: 100%;
            height: 100%;
            object-fit: cover;
          `}
        />
      }
      {
        loading && <PanelItemLoading />
      }
      {
        isShowCustomizeButton &&
          <div
            className="TemplateItem__CustomizeButtonContainer"
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                padding: 20px;
                pointer-events: none;
              `}
          >
            <motion.div
              variants={{
                rest: {
                  scale: 0,
                },
                hover: {
                  scale: 1,
                },
                focus: {
                  scale: 1,
                },
              }}
            >
              <CustomizeButton
                css={css`
                  pointer-events: auto;
                `}
              />
            </motion.div>
          </div>
      }
    </TemplateItemContainer>
  );
};
